import {useState, useEffect} from "react";
import calendarRuLocale from "../Const/DateRange/ru";
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import {Calendar, DayRange} from "@hassanmojab/react-modern-calendar-datepicker";

import {ReactComponent as CalendarIco} from "~/assets/img/ico/calendar.svg";
import {ReactComponent as PeopleIco} from "~/assets/img/ico/people.svg";
import {Select} from "~/Components/UI/Select";
import {initWidget} from "~/Components/Hotel/BookingWidget";

export const HotelBooking = () => {
    const [showCalendar, setShowCalendar] = useState("");
    const [selectedDayRange, setSelectedDayRange] = useState<DayRange>({
        from: null,
        to: null
    });

    useEffect(() => {
        // Клик вне
        document.addEventListener("click", clickOut);

        initWidget({
            id: "7974b6b9-3566-43fe-958a-087b82dfbbda",
            type: "verticalBlock",
            form: {
                // Замените на ID html элемента, в который нужно вставить виджет
                container: "booking-widget",
            }
        })

        return () => document.removeEventListener("click", clickOut);
    }, []);

    const clickOut = (e) => {
        if (!(e.target.classList.contains('booking-input') || e.target.closest('.booking-input'))) {
            setShowCalendar("");
        }

    }

    useEffect(() => {
        if (selectedDayRange.from !== null && selectedDayRange.to !== null) {
            setShowCalendar(() => "");
        }
    }, [selectedDayRange])

    const getCalendarDate = (date) => {
        if (date.from !== null && date.day) {
            return date.day + " " + date.month + " " + date.year;
        }

        return "";
    }

    return (
        <div className="booking-line">
            <div id="booking-widget"></div>

            {/* <div className="noise"></div>
            <div className="content visible booking-line-content">
                <h3>
                    Бронирование <br/>
                    номеров
                </h3>

                <div className={(showCalendar == 'left') ? "booking-input active" : "booking-input"}>
                    <label className="booking-input__label">
                        <input type="text"
                               placeholder=" "
                               value={getCalendarDate(selectedDayRange.from ? selectedDayRange.from : false)}
                               readOnly onClick={() => setShowCalendar("left")}/>
                        <span className="booking-input__placeholder">Заезд</span>
                        <CalendarIco/>
                    </label>
                    <Calendar
                        value={selectedDayRange}
                        onChange={setSelectedDayRange}
                        shouldHighlightWeekends
                        locale={calendarRuLocale}
                        colorPrimary="#C9A448FF" // added this
                        colorPrimaryLight="#EFEFFF" // and this
                    />
                </div>

                <div className={(showCalendar == 'right') ? "booking-input active" : "booking-input"}>
                    <label className="booking-input__label">
                        <input type="text"
                               placeholder=" "
                               value={getCalendarDate(selectedDayRange.to ? selectedDayRange.to : false)}
                               readOnly onClick={() => setShowCalendar("right")}/>
                        <span className="booking-input__placeholder">Выезд</span>
                        <CalendarIco/>
                    </label>
                    <Calendar
                        value={selectedDayRange}
                        onChange={setSelectedDayRange}
                        shouldHighlightWeekends
                        locale={calendarRuLocale}
                        colorPrimary="#C9A448FF" // added this
                        colorPrimaryLight="#EFEFFF" // and this
                    />
                </div>

                <div className="booking-input booking-input--big">
                    <label className="booking-input__label">
                        <Select>
                            <option value="">Гости</option>
                            <option value="1">1 взрослый, 0 детей</option>
                            <option value="2">2 взрослых, 0 детей</option>
                        </Select>
                        <PeopleIco/>
                    </label>
                </div>


                <button className="booking-line-btn">Найти номер</button>
            </div>*/}
        </div>
    )
}