import React, {useEffect} from "react";
import {Header} from "~/Components/base/Header";
import DocumentMeta from 'react-document-meta';
import axios, {AxiosError} from "axios";

import {meta_default} from "~/Components/Const/meta_default";
import {is_prod} from "~/Components/Function/isProd";

interface seoData {
    title: string,
    description: string
}


function Page(prop: { title: string; children }) {
    const meta = {...meta_default, title: prop.title ? meta_default.title + " - " + prop.title : meta_default.title};


    async function fetchSeoData() {
        try {
            const url = is_prod ? '/wp-admin/admin-ajax.php' : '/json/seo.json';
            let form_data = new FormData;

            if (is_prod) {
                form_data.append('action', 'get_seo');
                form_data.append('url', window.location.href);
            }

            const response = await axios.post<seoData>(url, form_data);

            let title = response.data.title;
            let description = response.data.description;

            if(title === '') title = prop.title

            document.title = title;
            document.querySelector('meta[name="description"]').setAttribute("content", description);


        } catch (e: unknown) {
            const error = e as AxiosError;
        }
    }

    useEffect(() => {
        fetchSeoData();
    })

    return (
        <main className="main-content padding-main">
            <Header/>
            {prop.children}
        </main>
    )
}

export default Page;