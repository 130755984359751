import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios, {AxiosError} from "axios";
import {ContactMap} from "./ContactMap";
import {is_prod} from "~/Components/Function/isProd";
import {html} from "~/Components/Function/html";

import {FooterProp} from "~/Components/Footer/FooterProp";

import {ReactComponent as SocIcoVk} from "~/assets/img/ico/soc_vk.svg";
import {ModalCallback} from "~/Components/Modal/ModalCallback";




export const Footer = () => {
    const [data, setData] = useState<FooterProp>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    async function fetchData() {
        try {
            setError('');
            setLoading(true);

            const url = is_prod ? '/wp-admin/admin-ajax.php' : '/json/footer.json';

            let form_data = new FormData;
            if (is_prod) {
                form_data.append('action', 'get_footer_info');
            }
            const response = await axios.post<FooterProp>(url, form_data);
            setData(response.data);
            setLoading(false);
        } catch (e: unknown) {
            const error = e as AxiosError;
            setLoading(false);
            setError(error.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])


    return <footer className="c-white footer">
        {error && <p>{error}</p>}
        {(!loading && error == '' && data) &&
			<div className="screen-cols">
				<div className="screen-cols__col">
					<ContactMap/>
					<Link to="/policy/" className="footer-policy h4">Политика конфиденциальности сайта</Link>
				</div>
				<div className="screen-cols__col footer-content">
					<div className="noise"></div>
					<div className="screen-col-content">
						<div className="h4 fade-down footer-address" dangerouslySetInnerHTML={html(data.address)}></div>
						<a href="tel:88002227485" className="h1 footer-tel fade-down">8 (800) 222 74 85</a>

						<div className="footer-contact-itm fade-down">
							<div className="h3 footer-subtitle">Ресторан</div>
							<div className="h4 footer-prop footer-tel-cols" dangerouslySetInnerHTML={html(data.rest_num)}></div>
                            {data.rest_vk &&
								<a href={data.rest_vk} target="_blank" rel="noreferrer" className="social-itm">
									<SocIcoVk/>
								</a>
                            }
						</div>

						<div className="footer-contact-itm footer-contact-grid fade-down">
							<div className="col">
								<div className="h3 footer-subtitle">Баня</div>
								<div className="h4 footer-prop" dangerouslySetInnerHTML={html(data.bath_num)}></div>
                                {data.bath_vk &&
									<a href={data.bath_vk} target="_blank" rel="noreferrer" className="social-itm">
										<SocIcoVk/>
									</a>
                                }
							</div>

							<div className="col">
								<div className="h3 footer-subtitle">Гостиница</div>
								<div className="h4 footer-prop" dangerouslySetInnerHTML={html(data.hotel_num)}></div>
                                {data.hotel_vk &&
									<a href={data.hotel_vk} target="_blank" rel="noreferrer" className="social-itm">
										<SocIcoVk/>
									</a>
                                }
							</div>
						</div>


						<div className="footer-copyright h4 fade-down">
							Создано в студии
							<a href="https://sawtech.ru" target="_blank" rel="noreferrer">Sawtech</a>
						</div>
					</div>
				</div>
			</div>
        }

    </footer>
}