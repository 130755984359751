import { ReactNode, Dispatch, SetStateAction} from 'react';

interface modalProps {
    children: ReactNode,
    state: boolean,
    setState: Dispatch<SetStateAction<boolean>>
    className: string
}

export const ModalBtn = ({children, setState, className}: modalProps) => {
    return (
        <div className={`modal-btn ${className}`} onClick={() => {
            console.log('click');
            setState(true)
        }}>
            {children}
        </div>
    );
}
