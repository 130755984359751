import React, {useEffect, useState} from 'react';
import axios, {AxiosError} from "axios";
import {RestaurantProp} from "./RestaurantProp";
import {Loader} from "~/Components/base/Loader";
import {Hero} from "~/Components/base/Hero";
import {PageInfoSection} from "~/Components/Page/PageInfoSection";
import {PageSlider} from "~/Components/Page/PageSlider";
import {Footer} from "~/Components/Footer/Footer";
import {html} from "~/Components/Function/html";
import {is_prod} from "~/Components/Function/isProd";
import {PictureSet} from "~/Components/base/PictureSet";

import menuImage from "~/assets/img/menu-img.jpg";

export const Restaurant = () => {
    const [data, setData] = useState<RestaurantProp>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    async function fetchData() {
        try {
            setError('');
            setLoading(true);
            const url = is_prod ? '/wp-admin/admin-ajax.php' : '/json/page.json';

            let form_data = new FormData;
            if (is_prod) {
                form_data.append('action', 'get_static_page');
                form_data.append('url', '/restaurant');
            }

            const response = await axios.post<RestaurantProp>(url, form_data);
console.log(response);
            setData(response.data);
            setLoading(false);
        } catch (e: unknown) {
            const error = e as AxiosError;
            setLoading(false);
            setError(error.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <div className="static-page page-restaurant">
            <Loader loading={loading}/>
            {error && <p>{error}</p>}
            {(!loading && error == '' && data) &&
				<div className="">
					<Hero data={data.hero}/>
					<PageInfoSection bg={data.info_bg} title={data.info_title}>
                        {data.info_text}
					</PageInfoSection>
					<div className="static-page-content-wrap">

						<div className="noise"></div>
						<div className="content content--limit page-content-padding padding-normal text-content"
							 dangerouslySetInnerHTML={html(data.page_content)}></div>

						<PageSlider items={data.slider}/>

                        {data.menu_link &&
						<div className="content">
							<div className="restaurant-menu-block padding-normal">

								<a href={data.menu_link} className="menu-img-link ta-c" target="_blank" rel="noreferrer">
									<img src={menuImage} alt="" className="menu-img" loading="lazy"/>

									<div className="restaurant-menu-block__link h3">
										Посмотреть меню <span className="no-line">(pdf 12 mb)</span>
									</div>
								</a>
							</div>
						</div>
                        }

                        {data.bz_bg &&
							<section className="section-block">

                                <div className="section-block__bg">
								<PictureSet className="fill-img" desktop={data.bz_bg?.large}
											mobile={data.bz_bg?.medium}/>
								</div>

								<div className="content">
									<h2 className="h1 margin-title">Большой банкетный зал РИОНИ</h2>
									<div className="text-format section-block__text" dangerouslySetInnerHTML={html(data?.bz_content)}></div>
								</div>

							</section>
                        }

                        {data.tab1_title &&
							<div className="screen-cols-section">
								<div className="screen-cols bg-white">
									<div className="screen-cols__col bg-dark">
										<div className="screen-col-content">
											<h2 className="h1 margin-title">{data?.tab1_title}</h2>
											<div className="text-format" dangerouslySetInnerHTML={html(data?.tab1_content)}></div>
										</div>
									</div>
									<div className="screen-cols__col">
										<div className="screen-col-content">
											<h2 className="h1 margin-title">{data?.tab2_title}</h2>
											<div className="text-format" dangerouslySetInnerHTML={html(data?.tab2_content)}></div>
										</div>
									</div>
								</div>

							</div>
                        }

						<Footer/>
					</div>
				</div>
            }
        </div>
    )
}