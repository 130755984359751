import React from "react";
import ReactDOM from "react-dom/client";
import {
    RouterProvider,
} from "react-router-dom";

import "./assets/scss/style.scss"
import "./assets/js/main.js"
import Routes from "~/Routes";

const router = Routes;

ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router}/>
);
