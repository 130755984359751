import {Dispatch, SetStateAction, useState, useRef} from 'react';
import {Modal} from "~/Components/Modal/Modal";
import {Checkbox} from "~/Components/UI/Checkbox";
import {is_prod} from "~/Components/Function/isProd";
import axios from "axios";
import {IMaskInput} from 'react-imask';

import "~/assets/js/field_init";


interface modalProps {
    state: boolean;
    setState: Dispatch<SetStateAction<boolean>>
}

interface modalResponse {
    result: boolean
}

export const ModalCallback = ({state, setState}: modalProps) => {
    const [success, setSuccess] = useState(false);
    const ref = useRef(null);
    const form = useRef(null);
    const inputRef = useRef(null);
    const btn = useRef(null)
    const tel_mask = "+7 (000) 000-00-00";

    const submitCallback = async (e) => {

        btn.current.classList.add('disabled')
        e.preventDefault();
        try {
            const url = is_prod ? '/wp-admin/admin-ajax.php' : '/json/success.json';

            let form_data = new FormData(form.current);
            if (is_prod) {
                form_data.append('action', 'formCallback');
            }

            const response = await axios.post<modalResponse>(url, form_data);

            if(response.data.result === true){
                setSuccess(true);
                ym(97494584,'reachGoal','formasubmit')
            }else{
                alert('Ошибка при отправке сообщения. Попробуйте позже')
            }
        } catch (error){
            alert('Ошибка при отправке сообщения. Попробуйте позже')
        }

    }

    return (
        <Modal state={state} setState={setState}>
            {success &&
				<div style={{marginTop: "2rem"}}>
					<p className="h2">Сообщение успешно отправлено</p>
                </div>
            }
            {!success &&
				<form className="form-callback nice-form js-form-validate" onSubmit={submitCallback} ref={form}>
					<div className="form-callback__title h1">Напишите нам</div>
					<div className="form-callback__col">
						<div className="input required">
							<input type="text" placeholder="Ваше имя*" name={"name"} required={true}/>
							<div className="input-hint"></div>
						</div>
						<div className="input required">
							<IMaskInput
								name={"phone"}
                                type={"tel"}
								mask={tel_mask}
								value=""
								unmask={true}
								ref={ref}
								inputRef={inputRef}
								onAccept={
                                    (value, mask) => {
                                        let self = inputRef.current;

                                        if (self.value.length < tel_mask.length) {
                                            self.closest('.input').classList.remove("phone-complete");
                                        } else {
                                           self.closest('.input').classList.add("phone-complete");
                                        }
                                    }
                                }
								placeholder='Ваш телефон*'
							/>
							<div className="input-hint"></div>
						</div>
						<div className="input">
							<input type="text" placeholder="Тема" name={"theme"}/>
							<div className="input-hint"></div>
						</div>
					</div>
					<div className="form-callback__col">
						<div className="input">
							<textarea name="message" placeholder="Сообщение"></textarea>
							<div className="input-hint"></div>
						</div>

						<div className="form-callback__actions">
                            <div className="input required">
							    <Checkbox name={'policy'} title={"Согласен на обработку личных данных"} className={""}
									  required={true} checked={true}/>
								<div className="input-hint"></div>
							</div>
							<button ref={btn} className="btn btn--main js-form-validate-btn">Отправить</button>
						</div>
					</div>
				</form>
            }
        </Modal>
    );
};