import {ReactNode, Dispatch, SetStateAction} from 'react';

interface modalProps {
    children: ReactNode
    state: boolean
    setState: Dispatch<SetStateAction<boolean>>
}

export const Modal = ({children, state, setState}: modalProps) => {
    return (
        <div className={`modal${state ? " active" : ""}`}>
            <div className="modal-fade" onClick={() => setState(false)}>
                <div className="noise"></div>
            </div>
            <div className="modal-content">
                <div className="content">
                    <div className="modal-close" onClick={() => setState(false)}>
                        <div className="cross"></div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
}
