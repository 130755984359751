import image404 from "~/assets/img/404.png";


export const Page404 = () => {
    return (
        <div className="page-404">
            <div className="page-404-content">
                <div className="page-404__title">Риони</div>
                <div className="page-404__brand">404</div>
                <div className="page-404__desc h3">Страница не найдена</div>
            </div>
            <div className="page-404__img">
                <img src={image404} alt=""/>
            </div>
            <div className="noise"></div>
        </div>
    )
}