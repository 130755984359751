import React, {useEffect, useState} from "react";
import {useParams, Link} from "react-router-dom";
import {TouristDetailProp} from "~/Props/TouristDetailProp";
import axios, {AxiosError} from "axios";
import {Loader} from "~/Components/base/Loader";
import {is_prod} from "~/Components/Function/isProd";

import {ReactComponent as Arrow} from "~/assets/img/ico/arrow.svg";
import {html} from "~/Components/Function/html";


export const TouristDetail = () => {
    let {slug} = useParams();

    const [data, setData] = useState<TouristDetailProp>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    async function fetchData() {
        try {
            setError('');
            setLoading(true);
            const url = is_prod ? '/wp-admin/admin-ajax.php' : '/json/tourist_detail.json';

            let form_data = new FormData;
            if (is_prod) {
                form_data.append('action', 'get_tourist_detail');
                form_data.append('slug', slug);
            }

            const response = await axios.post(url, form_data);
            setData(response.data);
            setLoading(false);

            document.title = response.data.title;
        } catch (e: unknown) {
            const error = e as AxiosError;
            setLoading(false);
            setError(error.message);
        }
    }

    useEffect(() => {
        fetchData();

        document.querySelector(".header-btn").classList.add("header-btn--dark");
        return function () {
            document.querySelector(".header-btn").classList.remove("header-btn--dark");
        };
    }, [])


    return (
        <>
            <Loader loading={loading}/>
            {error && <p>{error}</p>}
            {(!loading && error == '' && data) &&
				<div className="tourist-page">
					<div className="noise"></div>
                    <div className="content">
						<div className="alternative-head">
							<div></div>
							<a href="tel:+78002227485" className="h3">+7 (800) 222-74-85</a>
						</div>
						<Link to="/tourist" className="tourist-page__h1">
							<h2 className="h1">Туристический портал</h2>
						</Link>

						<div className="content-info">
                            {data?.cat && <div className="content-info-itm">{data.cat}</div>}
							<div className="content-info-itm">{data.date}</div>
                            {data?.view ? <div className="content-info-itm"><span className="view-count-text">Просмотров:</span> {data.view}</div> : ""}
						</div>

						<h1 className="margin-title">{data.title}</h1>

                        {data?.img?.large &&
							<div className="page-image">
								<img src={data.img.large} alt="" className="" loading="lazy"/>
							</div>

                        }
						<div className="text-format page-content" dangerouslySetInnerHTML={html(data.content)}></div>

						<div className="page-foot-link">
                            {data.prev_link ?
								<Link to={data.prev_link} reloadDocument={true} className="page-foot-link__itm">
									<Arrow className="arrow-rotate"/>
									<span>К предыдущей записи</span>
								</Link>
                                :
                                <div></div>
                            }
                            {data.next_link &&
								<Link to={data.next_link}reloadDocument={true} className="page-foot-link__itm">
									<span>К следующей записи</span>
									<Arrow/>
								</Link>
                            }
						</div>
					</div>
				</div>
            }
        </>
    )
}