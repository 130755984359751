import React, {useRef} from "react";
import {gsap} from "gsap";
import {html} from "~/Components/Function/html";

interface VerticalTabsProp {
    data: [
        {
            bg: string,
            title: string,
            text: string,
        },
        {
            bg: string,
            title: string,
            text: string,
        }
    ]
}

export const VerticalTabs = ({data}: VerticalTabsProp) => {
    const block = useRef<HTMLDivElement>();

    const clickActive = (k) => {
        if (!block.current) {
            return;
        }

        const itms_open = block.current.querySelectorAll(".v-clicked-tabs__itm.open"),
             itms = block.current.querySelectorAll(".v-clicked-tabs__itm");

        if (itms[k].classList.contains('open')) {
            hideBlock(itms[k]);
            return;
        }

        if (itms_open.length > 0) {
            hideBlock(itms_open[0]);
        }

        showBlock(itms[k]);
    }

    const hideBlock = (itm) => {
        gsap.to(itm.querySelectorAll(".v-clicked-tabs__itm__text"),
            {
                height: 0
            });
        itm.classList.remove('open');
    }


    const showBlock = (itm) => {
        gsap.to(itm.querySelectorAll(".v-clicked-tabs__itm__text"),
            {
                height: 'auto'
            });

        itm.classList.add('open');
    }

    return (
        <div ref={block} className="v-clicked-tabs">
            {data.map((itm, k) =>
                <div className='v-clicked-tabs__itm'
                     key={"v-clicked-tabs__itm" + k}
                     onClick={() => clickActive(k)}>
                    <img src={itm.bg} className="fill-img" alt="" loading="lazy"/>
                    <div className="v-clicked-tabs__itm__head">
                        <div className="v-clicked-tabs__itm__title h1">{itm.title}</div>
                        <div className="v-clicked-tabs__itm__arrow"></div>
                    </div>
                    <div className="v-clicked-tabs__itm__text" dangerouslySetInnerHTML={html(itm.text)}></div>
                </div>
            )}
        </div>
    )
}