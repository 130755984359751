import {HeroProps} from "~/Props/HeroProp";
import {html} from "~/Components/Function/html";
import React from "react";
import {PictureSet} from "~/Components/base/PictureSet";

interface HeroFuncProps {
    data: HeroProps,
    children?: React.ReactNode
}

export const Hero = ({data, children}: HeroFuncProps) => {
    return data &&
		<div className="hero">
			<div className="hero-bg">
				<PictureSet className="fill-img" desktop={data?.bg?.large}
							mobile={data?.bg?.large} loading="eager"/>
			</div>
			<div className="image-fade"></div>

			<div className="content content--limit padding-normal c-white hero-content">
				<h1 className="slider-itm__title margin-title">{data.title}</h1>
				<div className="text-format hero__text h3" dangerouslySetInnerHTML={html(data.text)}></div>
			</div>

            {children}

		</div>

}