import {afishaProp} from "./AfishaScreenProp";
import {PictureSet} from "~/Components/base/PictureSet";
import {html} from "~/Components/Function/html";

interface AfishaInterface {
    data: afishaProp,
    full?: boolean
}

export const AfishaScreen = ({data, full = true}: AfishaInterface) => {
    return (data &&
		<div className={full ? "afisha-screen" : "afisha-screen min-screen"}>
			<div className="full-screen"
				 style={{background: 'linear-gradient(107.61deg, ' + data.afisha_color1 + ' 0.7%, ' + data.afisha_color2 + ' 59.08%)'}}></div>
			<PictureSet className="afisha-screen__img fade-down-big" desktop={data?.afisha_img?.large}
						mobile={data?.afisha_img?.medium}/>

			<div className="content content--limit padding-normal">
				<div className="afisha-screen-content">
					<h2 className="h1 margin-subtitle fade-left">{data.afisha_title}</h2>
                    {data.afisha_desc &&
						<h4 className="margin-subtitle fade-left"
							dangerouslySetInnerHTML={html(data.afisha_desc)}></h4>}
                    {data.afisha_text && <h4 className="afisha-screen__text fade-left"
											 dangerouslySetInnerHTML={html(data.afisha_text)}></h4>}
					<h4 className="fade-left">{data.afisha_date}</h4>
                    {data.afisha_link && data.afisha_link_text &&
						<a href={data.afisha_link} target="_blank" rel={"noreferrer"} className="btn fade-left">{data.afisha_link_text}</a>
                    }
				</div>
			</div>
		</div>
    )
}