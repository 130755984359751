import {
    createBrowserRouter
} from "react-router-dom";
import Page from "~/Components/base/Page";
import {
    Afisha,
    Bath,
    Contacts,
    Guest,
    Home,
    Hotel,
    Page404,
    Restaurant,
    Tourist,
    TouristDetail,
    Veranda
} from "~/Pages";
import {Catering} from "~/Pages/Catering/Catering";

const Routes = createBrowserRouter([
    {
        path: "/", element: <Page title=""><Home/></Page>,
    },
    {
        path: "tourist", element: <Page title="Туристический портал"><Tourist/></Page>,
    },
    {
        path: "tourist/:slug", element: <Page title="Туристический портал"><TouristDetail/></Page>,
    },
    {
        path: "/afisha/", element: <Page title="Афиша"><Afisha/></Page>,
    },
    {
        path: "/restaurant/", element: <Page title="Ресторан"><Restaurant/></Page>,
    },
    {
        path: "/catering/", element: <Page title="Кейтеринг"><Catering/></Page>,
    },
    {
        path: "/bathhouse/", element: <Page title="Бани"><Bath/></Page>,
    },
    {
        path: "/hotel/", element: <Page title="Гостиница"><Hotel/></Page>,
    },
    {
        path: "/veranda/", element: <Page title="Летняя веранда"><Veranda/></Page>,
    },
    {
        path: "/about-us/", element: <Page title="Контакты"><Contacts/></Page>,
    },
    {
        path: "/guest/", element: <Page title="Гостевые дома"><Guest/></Page>,
    },
    {
        path: "*", element: <Page title="404"><Page404/></Page>
    }
]);

export default Routes;