import React, {useEffect, useState} from 'react';
import axios, {AxiosError} from "axios";
import {Loader} from "~/Components/base/Loader";
import {html} from "~/Components/Function/html";
import {is_prod} from "~/Components/Function/isProd";

import {ContactsProp} from "./ContactsProp";
import {FooterProp} from "~/Components/Footer/FooterProp";
import {ContactMap} from "~/Components/Footer/ContactMap";
import {ReactComponent as SocIcoVk} from "~/assets/img/ico/soc_vk.svg";

export const Contacts = () => {
    const [dataFooter, setDataFooter] = useState<FooterProp>();
    const [data, setData] = useState<ContactsProp>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    async function fetchData() {
        try {
            setError('');
            setLoading(true);
            const url = is_prod ? '/wp-admin/admin-ajax.php' : '/json/page.json';

            let form_data = new FormData;
            if (is_prod) {
                form_data.append('action', 'get_static_page');
                form_data.append('url', window.location.href);
            }

            const response = await axios.post<ContactsProp>(url, form_data);

            setData(response.data);

            const urlF = is_prod ? '/wp-admin/admin-ajax.php' : '/json/footer.json';

            let form_dataF = new FormData;
            if (is_prod) {
                form_dataF.append('action', 'get_footer_info');
            }
            const responseF = await axios.post<FooterProp>(urlF, form_dataF);
            setDataFooter(responseF.data);

            setLoading(false);
        } catch (e: unknown) {
            const error = e as AxiosError;
            setLoading(false);
            setError(error.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <div className="static-page page-contact">
            <Loader loading={loading}/>
            {error && <p>{error}</p>}
            {(!loading && error == '' && data) &&
				<div className="">
					<div className="static-page-content-wrap bg-dark">
						<div className="noise"></div>

						<div className="content padding-normal text-content">
							<h1 className="margin-title">{data.title}</h1>

							<div className="contact-grid">

								<div className="contact-grid-itm">
									<h3 className="h3 footer-subtitle">Ресторан</h3>
									<div className="h4 footer-prop" dangerouslySetInnerHTML={html(dataFooter.rest_num)}></div>
                                    {dataFooter.rest_vk &&
										<a href={dataFooter.rest_vk} target="_blank" rel="noreferrer" className="social-itm">
											<SocIcoVk/>
										</a>
                                    }
								</div>


								<div className="contact-grid-itm">
									<h3 className="h3 footer-subtitle">Баня</h3>
									<div className="h4 footer-prop" dangerouslySetInnerHTML={html(dataFooter.bath_num)}></div>
                                    {dataFooter.bath_vk &&
										<a href={dataFooter.bath_vk} target="_blank" rel="noreferrer" className="social-itm">
											<SocIcoVk/>
										</a>
                                    }
								</div>

								<div className="contact-grid-itm">
									<h3 className="h3 footer-subtitle">Гостиница</h3>
									<div className="h4 footer-prop" dangerouslySetInnerHTML={html(dataFooter.hotel_num)}></div>
                                    {dataFooter.hotel_vk &&
										<a href={dataFooter.hotel_vk} target="_blank" rel="noreferrer" className="social-itm">
											<SocIcoVk/>
										</a>
                                    }
								</div>

							</div>

							<div className="h4 page-contact-address" dangerouslySetInnerHTML={html(dataFooter.address)}></div>

							<div className="text-content page-contact-content"
								 dangerouslySetInnerHTML={html(data.page_content)}></div>
						</div>


						<div className="contact-page-map">
							<ContactMap/>
						</div>

					</div>

				</div>
            }
        </div>
    )
}