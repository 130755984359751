import React, {useEffect, useRef} from "react";
import ymaps from 'ymaps';

import placemark_src from '../../assets/img/ico/placemark.svg'

export const ContactMap = () => {
    let block_map = useRef<HTMLDivElement>();

    useEffect(() => {
        setTimeout(() => {

            ymaps
                .load('https://api-maps.yandex.ru/2.1/?lang=ru_RU')
                .then(maps => {
                    const map = new maps.Map('footer-map', {
                        center: [55.601915, 41.967152],
                        zoom: 7,
                    },{
                        maxZoom: 17
                    });
                    const objectManager = new maps.ObjectManager({
                        // Чтобы метки начали кластеризоваться, выставляем опцию.
                        clusterize: false,
                        clusterHasBalloon: false,
                        geoObjectOpenBalloonOnClick: true,
                        clusterOpenBalloonOnClick: false,
                        gridSize: 256
                    });
                    map.geoObjects.add(objectManager);
                    map.behaviors.disable('scrollZoom');
                    const placemark = new maps.Placemark([55.601915, 41.967152], {
                        balloonContent: "Hbjyb",
                    }, {
                        // Необходимо указать данный тип макета.
                        iconLayout: 'default#image',
                        // Своё изображение иконки метки.
                        iconImageHref: placemark_src,
                        // Размеры метки.
                        iconImageSize: [64, 80],
                        // Смещение левого верхнего угла иконки относительно
                        // её "ножки" (точки привязки).
                        iconImageOffset: [-32, -40]
                    });
                    map.geoObjects.add(placemark);

                    maps.geoQuery(placemark).applyBoundsToMap(map);  //делаем масштаб
                })
                .catch(error => console.log('Failed to load Yandex Maps', error));

        }, 5000)

    }, []);

    return (
        <div id="footer-map" ref={block_map} className="full-screen"></div>
    )
}