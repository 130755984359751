import {ImageSizesProp} from "~/Props/ImageSizesProp";
import {Autoplay, Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import React, {useState} from "react";
import {html} from "~/Components/Function/html";
import {is_prod} from "~/Components/Function/isProd";
import {getIndex} from "~/Components/Function/getIndex";
import axios from "axios";
import {Loader} from "~/Components/base/Loader";

interface AfishaSliderProp {
    items: [{
        "img": ImageSizesProp,
        "title": string,
        "desc": string
        "id": string
    }],
    desc?: string
}

interface innerSlidesProp {
    list: [
        img: ImageSizesProp,
        desc: string
    ]
}

export const AfishaSlider = ({items, desc}: AfishaSliderProp) => {
    const autoplay_time = 5000;
    const static_modal_class = "afisha-modal full-screen";
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    const [modalDesc, setModalDesc] = useState<string>("");
    const [innerSlides, setInnerSlides] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalSwiper, setModalSwiper] = useState(null);


    async function clickShowModal(index, id) {
        const url = is_prod ? '/wp-admin/admin-ajax.php' : '/json/afishaSlides.json';
        setLoading(true);
        let form_data = new FormData;
        if (is_prod) {
            form_data.append('action', 'get_afisha_slides');
            form_data.append('id', id);
        }

        const response = await axios.post<innerSlidesProp>(url, form_data);
        setInnerSlides(response.data.list)

        setModalTitle(items[index].title)
        setModalDesc(items[index].desc)

        setShowModal(true);
        setTimeout(() => {
            setLoading(false);
        }, 300)

    }

    return (
        <>
            <Loader loading={loading}/>
            <div className="content content--limit padding-normal">
                <div className="noise"></div>
                <h2 className="h1 ta-c">Прошедшие мероприятия</h2>
                <div className="afisha-slider">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={50}
                        navigation
                        loop={false}
                        className="afisha-swiper"
                        modules={[Navigation, Autoplay]}
                        autoplay={{
                            delay: autoplay_time,
                            disableOnInteraction: false,
                            waitForTransition: false
                        }}
                        breakpoints={
                            {
                                600: {
                                    slidesPerView: 2,
                                }
                            }
                        }
                    >
                        {

                            items.map((itm, key: number) => (
                                <SwiperSlide className="slider-itm" key={"main-slider-itm" + key}>
                                    <div className="slider-itm__bg"
                                         onClick={(e) => clickShowModal(getIndex(e.currentTarget.parentNode), itm.id)}>
                                        <img src={itm?.img?.large} alt="" loading="lazy"/>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                    {desc && <div className="afisha-desc" dangerouslySetInnerHTML={html(desc)}></div>}

                </div>
            </div>

            <div className={showModal ? static_modal_class + " active" : static_modal_class}>
                <div className="close-modal">
                    <div className="content">
                        <div className="cross" onClick={() => setShowModal(false)}></div>
                    </div>
                </div>

                <div className="content">
                    <p className="h1 afisha-modal__title">Прошедшие мероприятия</p>

                    <div className="afisha-modal__text">
                        <div className="afisha-modal__text__title h2">{modalTitle}</div>
                        <div className="afisha-modal__text__desc" dangerouslySetInnerHTML={html(modalDesc)}></div>
                    </div>
                </div>

                <Swiper
                    slidesPerView="auto"
                    loop={false}
                    className="front-gallery"
                    modules={[Navigation]}
                    onSwiper={setModalSwiper}
                    controller={modalSwiper}
                    centerInsufficientSlides={true}
                    centeredSlides={true}
                    onSlideChange={(e) => {
                        const index = e.activeIndex;
                        setModalDesc(innerSlides[index].desc);
                    }}
                >
                    {

                        innerSlides.map((itm, key: number) => (
                            <SwiperSlide className="slider-itm" key={"main-slider-itm" + key}>
                                <div className="slider-itm__bg">
                                    <img src={itm?.img?.large} alt="" loading="lazy"/>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>

            </div>
        </>
    )
}