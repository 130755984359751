import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectFade, Pagination, Autoplay} from "swiper";
import React, {useEffect, useRef} from "react";
import {html} from "~/Components/Function/html";
import {Link} from "react-router-dom";

import {HomeSliderProp} from "./HomeSliderProp";
import {gsap} from "gsap";
import {PictureSet} from "~/Components/base/PictureSet";


interface HomeSliderInterface {
    items: HomeSliderProp[]
}

export const HomeSlider = ({items}: HomeSliderInterface) => {
    const autoplay_time = 5000;
    const progress = useRef();

    useEffect(() => {
        startProgress();
    }, [])

    const swiperInit = (swiper) => {
        let dots = swiper.pagination.bullets;

        dots.map(((dot, index: number) => {
            let slide = swiper.slides[index],
                title = slide.querySelector('.slider-itm__title');
            if (title) {
                dot.innerHTML = '<p class="swiper-pagination-bullet__text">' +
                    '<span>' + title.textContent + '</span>' +
                    '<img src="' + title.getAttribute('data-icon') + '">' +
                    '</p>';
            }
        }))

        startProgress();
    }

    const startProgress = () => {
        if (!progress.current) {
            return false;
        }
        gsap.to(progress.current, {
            width: '0', duration: 0.2, overwrite: true, onComplete: () => {
                gsap.to(progress.current,
                    {
                        width: '100%',
                        ease: 'linear',
                        duration: autoplay_time / 1000 - 0.3
                    });
            }
        });
    }

    return (items &&
		<div className="home-slider">
			<Swiper
				slidesPerView={1}
				loop={true}
				className="full-screen slider-animation-fade front-slider"
				modules={[EffectFade, Pagination, Autoplay]} effect="fade"
				pagination={{clickable: true}}
				onSlideChange={() => {
                    startProgress()
                }}
				autoplay={{
                    delay: autoplay_time,
                    disableOnInteraction: false,
                    waitForTransition: false
                }}
				onSwiper={(swiper) => swiperInit(swiper)}
			>
                {

                    items.map((itm: HomeSliderProp, key: number) => (
                        <SwiperSlide className="slider-itm" key={"main-slider-itm" + key}>
                            <div className="slider-itm__bg">
                                <PictureSet className="" desktop={itm?.bg?.large} mobile={itm?.bg?.medium}/>
                            </div>
                            <div
                                className={"content content--limit c-white slider-itm-content" + (key === 0 ? ' first-slider-content' : '')}>
                                <div className="slider-itm__title" data-icon={itm?.icon?.thumbnail}>{itm?.title}</div>
                                <div className="slider-itm__desc Desk_Med_30"
                                     dangerouslySetInnerHTML={html(itm?.desc)}></div>
                                {itm.link !== '' &&
									<div className="slider-itm__link">
										<Link to={itm.link} className="btn">Подробнее</Link>
									</div>
                                }
                            </div>
                        </SwiperSlide>
                    ))
                }
			</Swiper>
			<div className="swiper-progress">
				<div className="swiper-progress-bar" ref={progress}></div>
			</div>
		</div>
    )
}