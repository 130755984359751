import {ImageSizesProp} from "~/Props/ImageSizesProp";
import {html} from "~/Components/Function/html";

interface PageInfoSectionProp {
    title: string,
    bg: ImageSizesProp,
    children
}

export const PageInfoSection = ({title, bg, children}: PageInfoSectionProp) => {
    return (
        <div className="page-info-section">
            <div className="noise"></div>
            <div className="content content--limit visible">
                <div className="page-info-section-content">
                    <div className="page-info-section__title h1">{title}</div>
                    <div className="page-info-section__text h4" dangerouslySetInnerHTML={html(children)}></div>
                </div>
                <img src={bg?.large} className="page-info-section__img" alt="" loading="lazy"/>
            </div>
        </div>
    )
}