import React, {useEffect, useState} from 'react';
import axios, {AxiosError} from "axios";
import {PageScroll} from "~/Components/base/PageScroll";
import {HomeProp} from "./HomeProp";
import {HomeSlider} from "~/Components/Home/HomeSlider";
import {HomeSliderProp} from "~/Components/Home/HomeSliderProp";
import {Loader} from "~/Components/base/Loader";
import {AfishaScreen} from "~/Components/Afisha/AfishaScreen";
import {Link} from "react-router-dom";
import {VerticalTabs} from "~/Components/base/VerticalTabs";

import {ReactComponent as Arrow} from "~/assets/img/ico/arrow.svg";
import {HomeGallery} from "~/Components/Home/HomeGallery";
import {Footer} from "~/Components/Footer/Footer";
import {html} from "~/Components/Function/html";
import {is_prod} from "~/Components/Function/isProd";
import {HotelBooking} from "~/Components/Hotel/HotelBooking";
import {PictureSet} from "~/Components/base/PictureSet";

export const Home = () => {

    const [data, setData] = useState<HomeProp>();
    const [dataSlider, setDataSlider] = useState<HomeSliderProp[]>()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    async function fetchData() {
        try {
            setError('');
            setLoading(true);
            const url = is_prod ? '/wp-admin/admin-ajax.php' : '/json/home.json';

            let form_data = new FormData;
            if (is_prod) {
                form_data.append('action', 'get_front');
            }
            const response = await axios.post<HomeProp>(url, form_data);
            setData(response.data);
            setDataSlider(response.data.main_slider)
            setLoading(false);
        } catch (e: unknown) {
            const error = e as AxiosError;
            setLoading(false);
            setError(error.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        console.log(data);
    }, [data])


    return (
        <div className="main-page">
            <Loader loading={loading}/>
            {error && <p>{error}</p>}
            {(!loading && error == '' && data) &&
				<PageScroll>

					<div className="full-screen" key="screen1">
						<HomeSlider items={dataSlider}/>
					</div>

                    {data?.afisha?.afisha_show == true &&
                        <>
                            <div className="full-screen">
                                <AfishaScreen data={data.afisha}/>
                            </div>
						</>
                    }

					<div className="full-screen" key="screen3">
						<div className="screen-cols bg-white">
							<div className="screen-cols__col with-img">
								<div className="fill screen-cols__col__img">
									<PictureSet className="fill-img" desktop={data.restaurant?.restaurant_bg?.large}
												mobile={data.restaurant?.restaurant_bg?.medium}/>
								</div>
							</div>
							<div className="screen-cols__col">
								<div className="noise"></div>
								<div className="screen-col-content">
									<div
										className="h1 margin-title fade-right">{data.restaurant?.restaurant_title}</div>
									<div
										className="text-format fade-right margin-big"
										dangerouslySetInnerHTML={html(data.restaurant?.restaurant_desc)}></div>
									<Link to="/restaurant" className="fade-right btn btn--dark">Подробнее</Link>
								</div>
							</div>
						</div>
					</div>

					<div className="full-screen" key="screen4">
						<div className="screen-cols c-white">
							<div className="screen-cols__col bath-content">
								<div className="noise noise--dark"></div>
								<div className="screen-col-content">
									<div className="h1 margin-title fade-right">{data.bath?.bath_main_title}</div>
									<div
										className="text-format fade-right h4"
										dangerouslySetInnerHTML={html(data.bath?.bath_main_desc)}></div>
								</div>
								<div className="bath-info">
									<div className="bath-info-list">
                                        {data.bath?.bath_main_info?.map((itm, k) =>
                                            <div className="bath-info-itm" key={"bath-info-itm" + k}>
                                                <img src={itm?.ico?.thumbnail} className="bath-info-itm__ico" alt=""
                                                     loading="lazy"/>
                                                <div className="bath-info-itm__name h4">
                                                    {itm?.name}
                                                </div>
                                            </div>
                                        )}
									</div>
								</div>
							</div>
							<div className="screen-cols__col">
								<VerticalTabs data={
                                    [
                                        {
                                            bg: data.bath?.bath_top_bg?.medium,
                                            title: data.bath?.bath_top_title,
                                            text: data.bath?.bath_top_desc
                                        },
                                        {
                                            bg: data.bath?.bath_bot_bg?.medium,
                                            title: data.bath?.bath_bot_title,
                                            text: data.bath?.bath_bot_desc
                                        }
                                    ]
                                }

								/>
							</div>
						</div>
					</div>

					<div className="full-screen" key="screen5">
						<div className="screen-cols bg-white with-booking">
							<div className="screen-cols__col with-img">
								<div className="fill screen-cols__col__img">
									<PictureSet className="fill-img" desktop={data.hotel_bg?.large}
												mobile={data.hotel_bg?.medium}/>
								</div>
							</div>
							<div className="screen-cols__col">
								<div className="noise"></div>
								<div className="screen-col-content">
									<div className="h1 margin-title fade-right">{data?.hotel_title}</div>
									<div
										className="text-format fade-right margin-big"
										dangerouslySetInnerHTML={html(data?.hotel_text)}></div>
									<Link to="/hotel" className="fade-right btn btn--dark">Подробнее</Link>
								</div>
							</div>
							<HotelBooking/>
						</div>
					</div>

					<div className="full-screen" key="screen6">
						<div className="screen-cols bg-white">
							<div className="screen-cols__col">
								<div className="noise"></div>
								<div className="screen-col-content">
									<div className="h1 margin-title fade-right">{data?.guest_title}</div>
									<div
										className="text-format fade-right margin-big"
										dangerouslySetInnerHTML={html(data?.guest_text)}></div>
									<Link to="/guest" className="fade-right btn btn--dark">Подробнее</Link>
								</div>
							</div>
							<div className="screen-cols__col with-img">
								<div className="fill screen-cols__col__img">
									<PictureSet className="fill-img" desktop={data.guest_bg?.large}
												mobile={data.guest_bg?.medium}/>
								</div>
							</div>
						</div>
					</div>


                    {/*<div className="full-screen flex-center" key="screen7">
                        <div className="noise noise--dark"></div>
                        <div className="content content--limit c-white tourist-list-block">
                            <div className="h1 fade-down">Туристический портал</div>
                            <div className="tourist-list-home">
                                {
                                    data.tourist && data?.tourist.map((itm, k) =>
                                        <div className="tourist-itm fade-down" key={"ti" + k}>
                                            <div className="tourist-itm-content">
                                                <div className="h4 margin-title">{itm?.date}</div>
                                                <Link to={itm?.link}
                                                      className="h2 margin-title tourist-itm__name">{itm?.title}</Link>
                                                <p className="tourist-itm__desc"
                                                   dangerouslySetInnerHTML={html(itm?.text)}></p>
                                                <Link to={itm?.link} className="tourist-itm__arrow">
                                                    <Arrow/>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>*/}

					<div className="full-screen flex-center gallery-screen home-gallery-block" key="screen8">
						<HomeGallery items={data?.gallery}/>
					</div>

					<div className="full-screen flex-center footer-screen" key="screen9">
						<Footer/>
					</div>

				</PageScroll>
            }
        </div>
    )
}