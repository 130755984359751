import React, {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import {isTablet} from "~/Components/Function/isTablet";
import {useMemo} from 'react';

interface PageScrollProps {
    children: React.ReactNode[]
}


const PageScroll = ({children}: PageScrollProps) => {
    const block = useRef(null);
    const [slide, setSlide] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [prevIndex, setPrevIndex] = useState(-1);
    let canMove = true;

    const moveUp = () => {
        setActiveIndex((activeIndex) => {
            setPrevIndex(activeIndex);
            if (activeIndex <= 0) return 0;
            return activeIndex - 1;
        });
    }

    const moveDown = () => {
        setActiveIndex((activeIndex) => {
            setPrevIndex(activeIndex);
            if (activeIndex >= slide.length - 1) return slide.length - 1;
            return activeIndex + 1
        });
    }

    const mousewheel = (e) => {
        if (!canMove || e.deltaY == 0) return;
        let toDown = e.deltaY > 0;

        canMove = false;

        if (toDown) {
            moveDown();
        } else {
            moveUp();
        }

        setTimeout(() => {
            canMove = true;
        }, 600);
    }

    useEffect(() => {
        animateChangeSlide();
    }, [activeIndex]);

    const animateChangeSlide = () => {
        let itms = block.current.querySelectorAll('.PageScroll-itm');

        if (isTablet) return;

        document.querySelector('.main-content').setAttribute('data-page', activeIndex.toString())

        if (itms[prevIndex]) {
            const down = itms[prevIndex].querySelectorAll('.fade-down'),
                down_big = itms[prevIndex].querySelectorAll('.fade-down-big'),
                left = itms[prevIndex].querySelectorAll('.fade-left'),
                right = itms[prevIndex].querySelectorAll('.fade-right'),
                img = itms[prevIndex].querySelectorAll('.screen-cols__col__img img')

            if (down.length > 0)
                gsap.to(down, {y: 50, opacity: 0, stagger: -.2, overwrite: true, duration: .4});

            if (down_big.length > 0)
                gsap.to(down_big, {y: 150, opacity: 0, stagger: -.2, overwrite: true, duration: .4});

            if (left.length > 0)
                gsap.to(left, {x: 50, opacity: 0, stagger: -.2, overwrite: true, duration: .4});

            if (right.length > 0)
                gsap.to(right, {x: -50, opacity: 0, stagger: -.2, overwrite: true, duration: .4});

            if (img.length > 0)
                gsap.to(img, {y: 0, scale: 1, overwrite: true, duration: 1.4, delay: .2});

        }
        if (itms[activeIndex]) {

            const down = itms[activeIndex].querySelectorAll('.fade-down'),
                down_big = itms[activeIndex].querySelectorAll('.fade-down-big'),
                left = itms[activeIndex].querySelectorAll('.fade-left'),
                right = itms[activeIndex].querySelectorAll('.fade-right'),
                img = itms[activeIndex].querySelectorAll('.screen-cols__col__img img')

            if (down.length > 0)
                gsap.fromTo(down,
                    {y: 50, opacity: 0},
                    {y: 0, opacity: 1, stagger: .2, overwrite: true, duration: .5, delay: .45});

            if (down_big.length > 0)
                gsap.fromTo(down_big,
                    {y: 150, opacity: 0},
                    {y: 0, opacity: 1, stagger: .2, overwrite: true, duration: 1});

            if (left.length > 0)
                gsap.fromTo(left,
                    {x: -50, opacity: 0},
                    {x: 0, opacity: 1, stagger: .2, overwrite: true, duration: .5, delay: .45});

            if (right.length > 0)
                gsap.fromTo(right,
                    {x: 50, opacity: 0},
                    {x: 0, opacity: 1, stagger: .2, overwrite: true, duration: .5, delay: .45});

            if (img.length > 0)
                gsap.to(img, {y: "-2%", scale: 1.1, overwrite: true, duration: 2.4});
        }
    }

    useMemo(() => {
        let removeChildren = [...children];

        children.forEach((i, k) => {
            if (i == false) {
                removeChildren.splice(k, 1);
            }
        });

        setSlide(removeChildren);
    }, [children])

    useEffect(() => {
        window.addEventListener('mousewheel', mousewheel)

        return () => {
            window.removeEventListener("mousewheel", mousewheel);
        }
    }, []);

    return (
        <div ref={block} className="PageScroll" data-page={activeIndex}>

            <div className="PageScroll-nav">
                {slide.map((child, key) => {
                    if (child == false) return <></>
                    return <div
                        onClick={() => {
                            setPrevIndex(activeIndex);
                            setActiveIndex(key)
                        }}
                        className={(key == activeIndex) ? 'PageScroll-nav-itm active' : 'PageScroll-nav-itm'}
                        key={"PageScroll-nav-itm" + key}></div>

                })}
            </div>

            {slide.map((child, key) => {
                    if (child == false) return <></>
                    return <div key={"PageScroll-itm" + key}
                                className={(key == activeIndex) ? 'PageScroll-itm active' : 'PageScroll-itm'}
                                data-key={key}>
                        {child}
                    </div>
                }
            )}
        </div>
    )


}

export {PageScroll};