import {ImageSizesProp} from "~/Props/ImageSizesProp";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Autoplay} from "swiper";
import React from "react";

interface PageSliderInterface {
    items: ImageSizesProp[],
}

export const PageSlider = ({items}: PageSliderInterface) => {
    const autoplay_time = 5000;

    const swiperInit = (swiper) => {
        let dots = swiper.pagination.bullets;

        dots.map(((dot: HTMLDivElement, index: number) => {
            let slide = swiper.slides[index],
                title = slide.querySelector('.slider-itm__title');
            if (title) {
                dot.innerHTML = '<p class="swiper-pagination-bullet__text">' + title.textContent + '</p>';
            }
        }))

    }


    return (items && items.length > 0 &&
		<div className={"page-slider"}>
			<Swiper
				slidesPerView={1}
				loop={false}
				className="front-gallery"
				modules={[Pagination, Autoplay]}
				pagination={{clickable: true}}
				autoplay={{
                    delay: autoplay_time,
                    disableOnInteraction: false,
                    waitForTransition: false
                }}
				onSwiper={(swiper) => swiperInit(swiper)}
			>
                {

                    items.map((itm: ImageSizesProp, key: number) => (
                        <SwiperSlide className="slider-itm" key={"main-slider-itm" + key}>
                            <div className="slider-itm__bg">
                                <img src={itm?.large} alt="" loading="lazy"/>
                            </div>
                        </SwiperSlide>
                    ))
                }
			</Swiper>
		</div>
    )
}