import React from "react";

interface PictureSetProp {
    desktop: string,
    mobile: string,
    className: string,
    loading?: "eager" | "lazy" | undefined;

}

export const PictureSet = ({desktop, mobile, className, loading = "lazy"}: PictureSetProp) => {
    return (
        <picture>
            <source srcSet={desktop + ".webp"} type="image/webp"/>
            <img srcSet={desktop}
                 alt=""
                 loading={loading}
                 className={className}/>

            <source srcSet={mobile} media="(max-width: 768px)"/>
            <source srcSet={mobile + ".webp"} media="(max-width: 768px)" type="image/webp"/>
        </picture>
    )
}