import {ReactComponent as LoaderInner} from "~/assets/img/loader/loader-inner1.svg";
import {ReactComponent as LoaderRound} from "~/assets/img/loader/loader-round.svg";
import {ReactComponent as LoaderRoundItm} from "~/assets/img/loader/loader-round-itm.svg";
import {ReactComponent as Filters} from "~/assets/img/loader/filters.svg";

interface loaderProp {
    loading: boolean
}

export const Loader = ({loading}: loaderProp) => {
    return (
        <div className={loading ? 'loader show' : 'loader'}>
            <Filters className="loader-anim-filter" />
            <div className="loader-anim">
                <LoaderInner className="loader-anim-inner"/>
                <LoaderRound className="loader-anim-dots"/>
                <LoaderRoundItm className="loader-anim-itm"/>
            </div>
        </div>
    )
}