interface checkboxProps {
    name: string,
    title: string,
    className: string,
    required: boolean,
    checked: boolean
}

export const Checkbox = ({name, title, className, required, checked}: checkboxProps) => {
    return (
        <label className={`check_box ${className}`}>
            <input type="checkbox" className="js-checkbox" placeholder={title} name={name} required={required} defaultChecked={checked}/>
            <span className="check_box__item"></span>
            <span className="check_box__text">{title}</span>
        </label>
    );
};