import og_img from "~/assets/img/og.jpg";

export const meta_default = {
    title: 'РИОНИ Гостинично-ресторанный комплекс',
    description: 'Ресторанно-гостиничный комплекс Риони в Муроме',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'Риони, ресторан, Муром'
        },
        'og:image': og_img,
    }
};