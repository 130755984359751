import React, {useEffect, useState} from 'react';
import axios, {AxiosError} from "axios";
import {BathProp} from "./BathProp";
import {Loader} from "~/Components/base/Loader";
import {Hero} from "~/Components/base/Hero";
import {PageInfoSection} from "~/Components/Page/PageInfoSection";
import {PageSlider} from "~/Components/Page/PageSlider";
import {Footer} from "~/Components/Footer/Footer";
import {html} from "~/Components/Function/html";
import {is_prod} from "~/Components/Function/isProd";


export const Bath = () => {
    const [data, setData] = useState<BathProp>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    async function fetchData() {
        try {
            setError('');
            setLoading(true);
            const url = is_prod ? '/wp-admin/admin-ajax.php' : '/json/page.json';

            let form_data = new FormData;
            if (is_prod) {
                form_data.append('action', 'get_static_page');
                form_data.append('url', window.location.href);
            }

            const response = await axios.post<BathProp>(url, form_data);

            setData(response.data);
            setLoading(false);
        } catch (e: unknown) {
            const error = e as AxiosError;
            setLoading(false);
            setError(error.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <div className="static-page bath-page">
            <Loader loading={loading}/>
            {error && <p>{error}</p>}
            {(!loading && error == '' && data) &&
				<div className="">
					<Hero data={data.hero}></Hero>
					<PageInfoSection bg={data.info_bg} title={data.info_title}>
                        {data.info_text}
					</PageInfoSection>
					<Hero data={data.subhero}></Hero>
                    <div className="static-page-content-wrap">
						<div className="noise"></div>
						<div className="content content--limit page-content-padding padding-normal text-content"
							 dangerouslySetInnerHTML={html(data.page_content)}></div>
						<PageSlider items={data.slider}/>
					</div>

					<Footer/>
				</div>
            }
        </div>
    )
}