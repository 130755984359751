import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Autoplay} from "swiper";
import React, {useRef} from "react";
import {ImageSizesProp} from "~/Props/ImageSizesProp";

import "swiper/css/effect-cards";

interface HomeGalleryInterface {
	items: ImageSizesProp[]
}

export const HomeGallery = ({items}: HomeGalleryInterface) => {
	const autoplay_time = 5000;
	const progress = useRef();


	const swiperInit = (swiper) => {
		let dots = swiper.pagination.bullets;

		dots.map(((dot: HTMLDivElement, index: number) => {
			let slide = swiper.slides[index],
				title = slide.querySelector('.slider-itm__title');
			if (title) {
				dot.innerHTML = '<p class="swiper-pagination-bullet__text">' + title.textContent + '</p>';
			}
		}))

	}


	return (items &&
		<div className="home-gallery">
			<div className="noise noise--dark"></div>
			<Swiper
				slidesPerView="auto"
				loop={false}
				className="full-screen front-gallery"
				modules={[Pagination, Autoplay]}
				pagination={{clickable: true}}
				centerInsufficientSlides={true}
				centeredSlides={true}
				autoplay={{
					delay: autoplay_time,
					disableOnInteraction: false,
					waitForTransition: false
				}}
				onSwiper={(swiper) => swiperInit(swiper)}
			>
				{

					items.map((itm: ImageSizesProp, key: number) => (
						<SwiperSlide className="slider-itm" key={"main-slider-itm" + key}>
							<div className="slider-itm__bg">
								<img src={itm?.large} alt="" loading="lazy"/>
							</div>
						</SwiperSlide>
					))
				}
			</Swiper>
			<div className="swiper-progress">
				<div className="swiper-progress-bar" ref={progress}></div>
			</div>
		</div>
	)
}