import {TouristList} from "~/Components/Tourist/TouristList";
import {useEffect, useState} from "react";
import {is_prod} from "~/Components/Function/isProd";
import axios, {AxiosError} from "axios";

interface touristCatItm {
    name: string,
    id: number
}

export const Tourist = () => {
    const [catList, setCatList] = useState<touristCatItm[]>([]);
    const [selectedCat, setSelectedCat] = useState<number[]>([]);

    useEffect(() => {
        let url = new URL(window.location.href),
            c = url.searchParams.get("cats");

        if (c) {
            setSelectedCat(c.split(",").map(Number));
        }

        fetchData();

        document.querySelector(".header-btn").classList.add("header-btn--dark");
        return function () {
            document.querySelector(".header-btn").classList.remove("header-btn--dark");
        };
    }, []);


    useEffect(() => {
        if (selectedCat.length > 0) {
            history.replaceState({}, '', '?cats=' + selectedCat.join(','));
        } else {
            history.replaceState({}, '', '?');
        }
    }, [selectedCat]);


    async function fetchData() {
        try {
            const url = is_prod ? '/wp-admin/admin-ajax.php' : '/json/tourist_cat_list.json';

            let form_data = new FormData;
            if (is_prod) {
                form_data.append('action', 'get_tourist_cat_list');
            }
            const response = await axios.post(url, form_data);
            setCatList(response.data.list);

        } catch (e: unknown) {
            const error = e as AxiosError;
            console.log(error);
        }
    }

    const clickCat = (id) => {
        setSelectedCat([...selectedCat, id])
    }

    function removeCat(id) {
        let arr = [...selectedCat];

        arr.splice(arr.indexOf(id), 1);

        setSelectedCat(arr)
    }


    return <div className="tourist-page">
        <div className="noise"></div>
        <div className="content">
            <div className="alternative-head">
                <div></div>
                <a href="tel:+78002227485" className="h3">+7 (800) 222-74-85</a>
            </div>
            <h1>Туристический портал</h1>


            {catList &&
				<div className="tourist-cat">
                    {catList.map((itm, k) => (
                        <div key={"tourist-cat-itm-" + k}
                             className={"tourist-cat-itm" + (selectedCat.indexOf(itm.id) !== -1 ? " active" : "")}>
                            <div data-cat-id={itm.id}
                                 className={"tourist-cat-itm__val h4"}
                                 onClick={() => clickCat(itm.id)}>
                                {itm.name}
                            </div>
                            <div className="remove-tourist-cat" onClick={() => removeCat(itm.id)}>
                                <div className="cross"></div>
                            </div>
                        </div>
                    ))}
				</div>
            }

            <TouristList cat={selectedCat}/>
        </div>
    </div>
}