export function initWidget(config) {
	let timeoutId;
	let context = window;

	if (!context)
		return;
	let tryInitWidget = function() {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		let HotelWidget = context.HotelWidget;
		let TIMEOUT_DELAY = 500;
		if (HotelWidget) HotelWidget.init(config, "https://bookonline24.ru/");
		else timeoutId = setTimeout(tryInitWidget, TIMEOUT_DELAY);
	};
	tryInitWidget();
	return function() {
		return clearTimeout(timeoutId);
	};
}