import {TouristItmProp} from "~/Props/TouristItmProp";
import {Link} from "react-router-dom";


import {ReactComponent as Arrow} from "~/assets/img/ico/arrow.svg";


interface TouristListItmProp {
    item: TouristItmProp
}

export const TouristListItm = ({item}: TouristListItmProp) => {
    return (
        <div className="tourist-list-itm">
            <Link to={item.link} className="tourist-list-itm__img-wrap">
                <div className="tourist-list-itm__img">
                    <img src={item.img.medium} alt="" loading="lazy"/>
                </div>
            </Link>
            <div className="tourist-list-itm__content">
                <div className="tourist-list-itm__date h4">{item.date}</div>
                <Link to={item.link} className="h3 tourist-list-itm__title">{item.title}</Link>
                <div className="tourist-list-itm__desc">{item.desc}</div>
                <Link to={item.link} className="tourist-list-itm__link"><Arrow/></Link>
            </div>
        </div>
    )
}