import React, {useEffect, useState} from "react";
import {TouristItmProp} from "~/Props/TouristItmProp";
import axios, {AxiosError} from "axios";
import {Loader} from "~/Components/base/Loader";
import {TouristListItm} from "~/Components/Tourist/TouristListItm";
import {is_prod} from "~/Components/Function/isProd";

interface TouristListProp {
    cat: number[]
}

export const TouristList = ({cat}: TouristListProp) => {
    const [data, setData] = useState<TouristItmProp[]>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const onPage = "4";

    useEffect(() => {
        fetchData();
    }, [cat]);

    async function fetchData() {
        try {
            setError('');
            setLoading(true);
            const url = is_prod ? '/wp-admin/admin-ajax.php' : '/json/tourist_list.json';

            let form_data = new FormData;
            if (is_prod) {
                form_data.append('action', 'get_tourist_list');
                form_data.append('onPage', onPage);
                form_data.append('category', cat.join(','));
            }
            const response = await axios.post(url, form_data);
            setData(response.data.list);
            setLoading(false);
        } catch (e: unknown) {
            const error = e as AxiosError;
            setLoading(false);
            setError(error.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <>
            <Loader loading={loading}/>
            {error && <p>{error}</p>}
            <div className="tourist-list">
                {(!loading && error == '' && data) ?
                    data.map((itm, key) => <TouristListItm key={"TouristListItm-" + key} item={itm}/>)
                    :
                    <>
                        <div className="tourist-list-itm skeleton"></div>
                        <div className="tourist-list-itm skeleton"></div>
                    </>
                }
            </div>
        </>
    )
}